<template>
  <n-config-provider
    :theme="globalTheme"
    class="overview"
    :class="globalTheme == null ? 'light' : 'dark'"
  >
    <n-global-style />
    <router-view />

    <n-button
      text
      class="ToggleTheme"
      @click="globalTheme == null ? globalTheme = darkTheme : globalTheme = null"
    >
      <vue-feather
        type="sun"
        v-if="globalTheme"
      />
      <vue-feather
        type="moon"
        v-else
      />
    </n-button>
  </n-config-provider>
</template>

<script>
import { ref } from 'vue'
import { NConfigProvider, NGlobalStyle, darkTheme, NButton } from 'naive-ui'
export default {
  components: {
    NConfigProvider,
    NGlobalStyle,
    NButton
  },
  setup () {
    return {
      darkTheme,
      globalTheme: ref(darkTheme)
    }
  }
}
</script>
<style lang="scss" scoped>
.overview {
  min-height: 100vh;
  .ToggleTheme {
    position:fixed;
    bottom: 3%;
    right: 3%;
    background: rgba(34,34,34,0.5);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
</style>
